<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
  <About />
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";

import Footer from "../components/Footer.vue";
import About from "../components/home/About.vue";


/**
 * Dashboard component
 */
export default {

  components: {
    Header,
    About,
    Footer,
  },

};
</script>